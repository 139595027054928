import { apiGet, apiPost, useOrsysContext } from '../../utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query/src/types';
import { RestaurantDetailedReadDTO } from '@orsys/common';

export const useRestaurantDetailedKey = (id?: number) => ['restaurant', 'detailed', id];

export function useRestaurantDetailedQuery(
  id?: number,
  {
    options
  }: {
    options?: UseQueryOptions<any>;
  } = {}
) {
  const queryClient = useQueryClient();
  const { user } = useOrsysContext();
  return useQuery({
    ...(options || {}),
    queryKey: useRestaurantDetailedKey(id),
    queryFn: async () => {
      const { data: response } = await apiGet<RestaurantDetailedReadDTO>(`/restaurant/${id}/detailed`);
      return response;
    },
    enabled: user?.id != null && id != null
  });
}
