import { DTO, OrsysException } from 'libs/common/src/models/GeneralModels';
import { errorToast } from './ToastUtils';
import { WithIntlComponentProps } from './i18n';
import { injectIntl } from 'react-intl';
import { PublicRuntimeConfig } from '@orsys/config';


const {
  backend,
  version
} = PublicRuntimeConfig;

export const LOGIN_API = '/auth/login';
export const LOGOUT_API = '/auth/logout';

const enum METHODS {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export const enum CONTENT_TYPES {
  TEXT = 'text/plain; charset=UTF-8',
  HTML = 'text/html; charset=UTF-8',
  JSON = 'application/json; charset=UTF-8',
  MULTIPART_FORM = 'multipart/form-data',
  FORM = 'application/x-www-form-urlencoded',
}

export interface ApiResponse<T> {
  data: T;
  headers: Headers;
}

export function apiPost<T extends DTO | void | string | number | boolean>(
  path: string,
  body?: BodyInit | null,
  contentType?: CONTENT_TYPES
): Promise<ApiResponse<T>> {
  return apiGeneral<T>(METHODS.POST, path, body, contentType);
}

export function apiGet<T extends DTO | void | string | number | boolean>(
  path: string,
  body?: BodyInit | null,
  contentType?: CONTENT_TYPES
): Promise<ApiResponse<T>> {
  return apiGeneral<T>(METHODS.GET, path, body, contentType);
}

export function apiPut<T extends DTO | void | string | number | boolean>(
  path: string,
  body?: BodyInit | null,
  contentType?: CONTENT_TYPES
): Promise<ApiResponse<T>> {
  return apiGeneral<T>(METHODS.PUT, path, body, contentType);
}

export function apiDelete<T extends DTO | void | string | number | boolean>(
  path: string,
  body?: BodyInit | null,
  contentType?: CONTENT_TYPES
): Promise<ApiResponse<T>> {
  return apiGeneral<T>(METHODS.DELETE, path, body, contentType);
}

async function apiGeneral<T extends DTO | void | string | number | boolean>(
  method: METHODS,
  path: string,
  body?: BodyInit | null,
  contentType?: CONTENT_TYPES
): Promise<ApiResponse<T>> {
  let headers: HeadersInit = new Headers();
  if (contentType) {
    headers.append('Content-Type', contentType);
  }
  if (path === LOGIN_API) {
    headers.append('Authorization', 'Basic YWRtaW5Mb2dpbkNsaWVudDpyRCMjdDR2K0B2ZFpUKnFTKiVXODVHZ0Z0Ul8jZXlZZQ==');
  }
  headers.append('Orsys-Client-Id', 'ADMIN');
  let versionNumber = parseInt(version.replaceAll('.', ''));
  if (versionNumber < 1000) {
    versionNumber = versionNumber + 1000;
  }
  headers.append('Orsys-Client-Version', `${versionNumber}`);
  const response = await fetch(`${backend}${path}`, {
    credentials: 'include',
    method: method,
    body: body,
    headers: headers
  });
  let exception = {
    exceptionMessage: 'toast.error',
    additionalInfo: ''
  };
  let data: T | any = null;
  if (!response.ok) {
    try {
      exception = await response.json();
      console.log('exception.exceptionMessage', exception.exceptionMessage);
      exception.exceptionMessage = `backend_exceptions.${exception.exceptionMessage}`;
    } catch (e) {
    }

    if (exception?.exceptionMessage && exception?.exceptionMessage.localeCompare('backend_exceptions.undefined') !== 0) {

      if (response.status === 401) {
        exception = {
          exceptionMessage: 'auth.session_expired',
          additionalInfo: ''
        };
        const searchParams = new URLSearchParams();
        if (!window.location.pathname.startsWith('/auth') && window.location.pathname !== '/') {
          searchParams.append('redirect', `${window.location.pathname}${window.location.search}`);
        }

        const context = localStorage.getItem('context');

        if (context) {
          const contextObject = JSON.parse(context);
          searchParams.append('restaurantId', contextObject?.restaurant?.id?.toString());
        }


        window.location.href = `/auth?${searchParams.toString()}`;
      }
      errorToast(<ErrorComponent exception={exception} />, {
        autoClose: 5000,
        toastId: exception.exceptionMessage
      });
    }

    throw exception;
  }

  if (method !== METHODS.DELETE) {
    try {
      if (response.headers.get('content-type')?.includes('text/plain')) {
        data = await response.text();
      } else {
        data = await response.json();
      }
    } catch (e) {
      try {
        data = await response.text();
      } catch (e) {
        if (path !== LOGOUT_API) {
          // errorToast(<ErrorComponent exception={exception}/>, {
          //     autoClose: 5000
          // });
          // throw exception;
        }
      }
    }
  }
  let result: ApiResponse<T> = {
    headers: response.headers,
    data: data
  };

  return new Promise((resolve, reject) => {
    resolve(result);
  });
}

interface ErrorComponentProps extends WithIntlComponentProps {
  exception: OrsysException;
  backendError?: boolean;
}

const ErrorComponent = injectIntl<'intl', ErrorComponentProps>(({ intl, exception, backendError }) => {
  return <>{intl.formatMessage({ id: exception.exceptionMessage }, { param: exception.additionalInfo })}</>;
});

export const getQueryKeyPathsFromObject = (obj: any): string[] => {
  if (obj == null) {
    return ['null'];
  }
  return Object.keys(obj).map(key => {
    if (Array.isArray(obj[key])) {
      return obj[key].map((item: any) => item.toString()).join(',');
    } else if (typeof obj[key] === 'object') {
      return getQueryKeyPathsFromObject(obj[key]).join(',');
    }
    return obj[key]?.toString();
  });
};
