export type PaymentTypeType =
  'CASH'
  | 'CASH_LARGE_BILLS'
  | 'CREDIT_CARD_TERMINAL'
  | 'SIMPLEPAY_OTP'
  | 'BARION'
  | 'APPLE_PAY'
  | 'GOOGLE_PAY'
  | 'OTP_SZEP_CARD_ONLINE'
  | 'OTP_SZEP_CARD_TERMINAL'
  | 'MBH_SZEP_CARD_ONLINE'
  | 'MBH_SZEP_CARD_TERMINAL'
  | 'KH_SZEP_CARD_ONLINE'
  | 'KH_SZEP_CARD_TERMINAL'
  | 'CUSTOM';

export interface WhitelabelRestaurantPaymentOptionApiReadDto {
  id?: number,
  name: {
    [key: string]: string,
  },
  description?: {
    [key: string]: string,
  },
  online: boolean,
  enabledForDelivery: boolean,
  enabledForTakeAway: boolean,
  sortingNumber: number,
  type: PaymentTypeType,
}

export interface WhitelabelRestaurantPaymentTypeReadDto extends WhitelabelRestaurantPaymentOptionApiReadDto {
  posText?: string,
  apiKey: string,
  apiMode: API_MODE,
  type: PaymentTypeType,
  deletable: boolean,
}

export interface WhitelabelAvailablePaymentTypeReadDto {
  id?: number,
  name?: {
    [key: string]: string,
  },
  description?: {
    [key: string]: string,
  },
  posText?: string,
  online: boolean,
  enabledByDefault: boolean,
  copiedByDefault: boolean,
  sortingNumber: number,
  deletable: boolean,
  type: PaymentTypeType
}

export enum API_MODE {
  TEST = 'TEST',
  LIVE = 'LIVE',
  STOP = 'STOP'
}

export interface WhitelabelRestaurantPaymentOptionWriteDto {
  id?: number;
  name?: {
    [key: string]: string;
  };
  description?: {
    [key: string]: string;
  };
  posText?: string,
  enabledForDelivery?: boolean;
  enabledForTakeAway?: boolean;
  sortingNumber?: number;
  apiKey?: string
  apiMode?: API_MODE
}
