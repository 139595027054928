import { apiGet, useOrsysContext } from '../../utils';
import { useQuery } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query/src/types';
import { ApiConnectionDTO, RestaurantApiConnectionWrapper, RestaurantOptionsGroupDTO } from '../../models';

export const apiConnectionByRestaurantKey = (restaurantId?: number) => ['api-connection', 'by-restaurant', restaurantId];

export function useApiConnectionByRestaurantQuery({
                                                    options
                                                  }: {
                                                    options?: UseQueryOptions<ApiConnectionDTO[]>;
                                                  } = {}
) {
  const { user, restaurant } = useOrsysContext();
  return useQuery({
    ...(options || {}),
    gcTime: 0,
    queryKey: apiConnectionByRestaurantKey(restaurant?.id),
    queryFn: async () => {
      const { data: response } = await apiGet<ApiConnectionDTO[]>(`/api-connection/by-restaurant/${restaurant?.id}`);
      return response;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    enabled: user?.id != null && restaurant?.id != null
  });
}
