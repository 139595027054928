import {
  apiGet,
  apiPost, convertFromRestaurantMinimalReadToSelect,
  getBooleanSetting,
  getStringSetting,
  OrsysContextProviderValues,
  useOrsysContext
} from '../../utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query/src/types';
import { RestaurantMinimalReadDTO, RestaurantRole, StaffWrapper, StaffWriteWrapper } from '../../models';

export const staffByIdKey = (staffId?: string) => ['staff', staffId];

export function useStaffByIdQuery(
  staffId?: string,
  {
    options
  }: {
    options?: UseQueryOptions<any>;
  } = {}
) {
  const queryClient = useQueryClient();
  const { user, restaurant } = useOrsysContext();


  return useQuery<StaffWriteWrapper>({
    ...(options || {}),
    gcTime: 0,
    queryKey: staffByIdKey(staffId),
    queryFn: async () => {
      let staff: StaffWriteWrapper = {
        user: {
          username: '',
          firstName: '',
          lastName: '',
          password: ''
        },
        restaurants: [
          {
            restaurantId: restaurant?.id || 0,
            restaurantRole: RestaurantRole.COURIER,
            primary: true
          }
        ]
      };

      try {
        const { data: responseRestaurantAll } = await apiGet<RestaurantMinimalReadDTO[]>(`/restaurant/allminimal`);
        let sequenceNumber: number = 0;

        if (restaurant && restaurant.id) {
          sequenceNumber = (await apiGet<number>(`/restaurant/${restaurant.id}/sequence/staff`)).data;
        }

        if (staffId != 'new') {
          const { data: response } = await apiGet<StaffWrapper>(`/user/staff/${staffId}`);

          staff.user = {
            id: response.user.id,
            username: response.user.username,
            firstName: response.user.firstName,
            lastName: response.user.lastName,
            email: response.user.email,
            phone: response.user.phone
          };

          staff.restaurants = response.restaurants;

        } else {
          staff.restaurants[0].sequenceNumber = sequenceNumber;
        }
      } catch (e) {
        //    TODO handle error
      }


      return staff;
    },
    initialData: {
      user: {
        username: '',
        firstName: '',
        lastName: '',
        password: ''
      },
      restaurants: [
        {
          restaurantId: restaurant?.id || 0,
          restaurantRole: RestaurantRole.COURIER,
          primary: true
        }
      ]
    },
    enabled: user?.id != null && staffId != null && staffId != 'new' && restaurant?.id != null
  });
}
