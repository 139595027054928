// @ts-nocheck

export function convertArrayOfObjectsToCSV(array, ignoreFields:string[] = []) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    let keys = Object.keys(array[0]);
    keys = keys.filter((key)=>!ignoreFields.includes(key));

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;

            result += item[key] || "-";
            // eslint-disable-next-line no-plusplus
            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}

export function downloadCSV(array, ignoreFields:string[] = [], fileName: string = "export.csv") {
  try {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array, ignoreFields);
    if (csv == null) return;

    const filename = fileName;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  } catch (error) {
    console.error(error);
  }
}

export function downloadCSR(content: string, fileName: string = "export.csv") {
    const link = document.createElement('a');

    const csrContent = `data:plain/text;charset=utf-8,${content}`;

    link.setAttribute("href", encodeURI(csrContent));
    link.setAttribute("download", fileName);
    link.click();
}
