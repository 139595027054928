import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AddressDTO, CoordinateDto, getAddress, WhitelabelUserAddressDto } from '@orsys/common';
import axios from 'axios';
import { PublicRuntimeConfig } from '@orsys/config';

const { googleMapsApiKey } = PublicRuntimeConfig;

export function useGoogleMapsCoordinatesByAddressMutation(options?: UseMutationOptions<{
  lat?: number,
  lon?: number
} | undefined, Error, AddressDTO>) {
  return useMutation({
    mutationFn: async (addressDto: AddressDTO) => {
      let result: CoordinateDto | undefined = undefined;
      const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          address: getAddress(addressDto),
          key: googleMapsApiKey
        }
      });

      // Check if the API returned results
      if (response.data.results.length > 0) {
        const location = response.data.results[0].geometry.location;
        result = { lat: location.lat, lon: location.lng };
      }

      return result;
    },
    ...(options ?? {})
  });
}

export function useGoogleMapsFirstResultByAddressMutation(options?: UseMutationOptions<WhitelabelUserAddressDto[], Error, string>) {
  return useMutation({
    mutationFn: async (address: string) => {
      let result: CoordinateDto | undefined = undefined;
      const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          address: address,
          key: googleMapsApiKey
        }
      });

      // Check if the API returned results
      // if (response.data.results.length > 0) {
      //   const location = response.data.results[0].geometry.location;
      //   result = { lat: location.lat, lon: location.lng };
      // }

      return (response?.data?.results ?? []).map((place: any) => {
        if (place.geometry && place.geometry.location) {
          const lat = place.geometry.location.lat;
          const lon = place.geometry.location.lng;
          const addressComponents = place.address_components || [];

          const getAddressComponent = (type: string) => {
            const component = addressComponents.find((comp: any) => comp.types.includes(type));
            return component ? component.long_name : '';
          };

          return {
            city: getAddressComponent('locality') || getAddressComponent('administrative_area_level_1'),
            state: getAddressComponent('administrative_area_level_2'),
            address: getAddressComponent('route'),
            number: getAddressComponent('street_number'),
            zip: getAddressComponent('postal_code'),
            position: { lat, lon }
          };
        }
      });
    },
    ...(options ?? {})
  });
}
