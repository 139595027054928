export const enum Packages {
    catering_package = "catering_package",
    delivery_package = "delivery_package",
    e_cash_register = "e_cash_register",
    qr_addon = "qr_addon",
    mini = "mini",
    basic = "basic",
    premium = 'premium',
    google_maps_plugin = 'google_maps_plugin',
    whitelabel_site = 'whitelabel_site',
    invoicing = 'invoicing',
    inventory = 'inventory',
}
