import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  apiDelete,
  apiPut,
  CONTENT_TYPES,
  CoordinateDto,
  useOrsysContext,
  WhitelabelContactDto, WhitelabelUnavailabilityReadDto, WhitelabelUnavailableAllDayDto,
  WhitelabelUnavailableImmediateDto, WhitelabelUnavailablePartialDto
} from '@orsys/common';


export function useWhitelabelContactUpdateMutations(options?: UseMutationOptions<any | undefined, Error, WhitelabelContactDto[]>) {
  const { restaurant } = useOrsysContext();
  return useMutation({
    mutationFn: async (values: WhitelabelContactDto[]) => {
      const response = await apiPut(`/whitelabel-admin/restaurant/${restaurant?.id}/contacts`, JSON.stringify(values), CONTENT_TYPES.JSON);

      return response?.data;
    },
    ...(options ?? {})
  });
}

export function useWhitelabelUnavailableUpdateMutations(options?: UseMutationOptions<any | undefined, Error, WhitelabelUnavailabilityReadDto>) {
  const { restaurant } = useOrsysContext();
  return useMutation({
    mutationFn: async (values: WhitelabelUnavailabilityReadDto) => {
      const response = await apiPut(`/whitelabel-admin/restaurant/${restaurant?.id}/unavailable/${values.id}`, JSON.stringify(values), CONTENT_TYPES.JSON);

      return response?.data;
    },
    ...(options ?? {})
  });
}

export function useWhitelabelPartialUnavailableMutations(options?: UseMutationOptions<WhitelabelUnavailabilityReadDto | undefined, Error, WhitelabelUnavailablePartialDto>) {
  const { restaurant } = useOrsysContext();
  return useMutation({
    mutationFn: async (values: WhitelabelUnavailablePartialDto) => {
      const response = await apiPut<WhitelabelUnavailabilityReadDto>(`/whitelabel-admin/restaurant/${restaurant?.id}/unavailable/partial`, JSON.stringify(values), CONTENT_TYPES.JSON);

      return response?.data;
    },
    ...(options ?? {})
  });
}

export function useWhitelabelAllDayUnavailableMutations(options?: UseMutationOptions<WhitelabelUnavailabilityReadDto | undefined, Error, WhitelabelUnavailableAllDayDto>) {
  const { restaurant } = useOrsysContext();
  return useMutation({
    mutationFn: async (values: WhitelabelUnavailableAllDayDto) => {
      const response = await apiPut<WhitelabelUnavailabilityReadDto>(`/whitelabel-admin/restaurant/${restaurant?.id}/unavailable/all-day`, JSON.stringify(values), CONTENT_TYPES.JSON);

      return response?.data;
    },
    ...(options ?? {})
  });
}

export function useWhitelabelImmediateUnavailableMutations(options?: UseMutationOptions<WhitelabelUnavailabilityReadDto | undefined, Error, WhitelabelUnavailableImmediateDto>) {
  const { restaurant } = useOrsysContext();
  return useMutation({
    mutationFn: async (values: WhitelabelUnavailableImmediateDto) => {
      const response = await apiPut<WhitelabelUnavailabilityReadDto>(`/whitelabel-admin/restaurant/${restaurant?.id}/unavailable/immediate`, JSON.stringify(values), CONTENT_TYPES.JSON);

      return response?.data;
    },
    ...(options ?? {})
  });
}

export function useWhitelabelDeleteUnavailableMutations(options?: UseMutationOptions<number | undefined, Error, number>) {
  const { restaurant } = useOrsysContext();
  return useMutation({
    mutationFn: async (id) => {
      const response = await apiDelete(`/whitelabel-admin/restaurant/${restaurant?.id}/unavailable/${id}`);

      return id;
    },
    ...(options ?? {})
  });
}
