import { apiGet, useOrsysContext } from '../../utils';
import { useQuery } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query/src/types';
import { RestaurantOptionsGroupDTO } from '../../models';

export const useSettingsByTextIdKey = (restaurantId?: number, textId?: string) => ['settings', 'group', 'by-text-id', restaurantId, textId];

export function useSettingsByTextIdQuery(textId: string,
  {
    options
  }: {
    options?: UseQueryOptions<RestaurantOptionsGroupDTO>;
  } = {}
) {
  const { user, restaurant } = useOrsysContext();
  return useQuery({
    ...(options || {}),
    gcTime: 0,
    queryKey: useSettingsByTextIdKey(restaurant?.id, textId),
    queryFn: async () => {
      const { data: response } = await apiGet<RestaurantOptionsGroupDTO>(
        `/restaurant/${restaurant?.id}/setting/group/by-text-id/${textId}`
      );
      (response?.options || []).sort((a, b) => (a.order || 0) - (b.order || 0));
      (response?.subgroups || []).sort((a, b) => (a.order || 0) - (b.order || 0));
      return response;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    enabled: user?.id != null && restaurant?.id != null && textId != null
  });
}
