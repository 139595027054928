import { AddressDTO, AuditedDTO, DTO } from '../GeneralModels';
import { TableReadDTO } from '../TableModels';
import { UserMinimalReadDTO } from '../UserModels';
import { CustomerMinimalReadDto } from '../CustomerModels';
import { MenuItemMinimalReadDTO } from '../menu/MenuItemModels';
import { ToppingMinimalReadDTO } from '../menu/ToppingModels';
import { DeliveryOrderStatus, LocalOrderStatus, OrderChangeType } from '../enum/Order';
import { RestaurantMinimalReadDTO } from '../RestaurantModels';
import { DeliveryZoneReadDTO } from './DeliveryModels';

export enum DeliveryType {
	DINE_IN = 'DINE_IN',
	TAKE_AWAY = 'TAKE_AWAY',
	DELIVERY = 'DELIVERY',
}

export interface OrderChangeDto {
	id: number;
	changeType: OrderChangeType;
	remoteId: string;
	textKey: string;
	values: string;
	createdAt: Date;
}

export interface DeliveryOrderMinimalReadDTO extends AuditedDTO {
	id: number;
	dailyOrderNumber?: number;
	deliveryTime?: Date;
	scheduledTime?: Date;
	grandTotal?: number;
	discountSum?: number;
	collectFromCustomer: number;
	customer?: CustomerMinimalReadDto;
	source?: string;
	statusGroup: string;
  externalApiIdShort?: string;
  externalApiIdFull?: string;
	ntakStatus?: string;
	paymentType?: string;
	status?: DeliveryOrderStatus;
	deliveryAddress?: AddressDTO;
  billingAddress?: AddressDTO;
  depositFees?: {
    [key: string | number]: number;
  };
	deliveryZone?: DeliveryZoneReadDTO;
}

export interface DeliveryOrderDetailedReadDTO extends DeliveryOrderMinimalReadDTO {
	deliveryType?: string;
	deliveryFee?: number;
	packagingFee?: number;
	paid?: boolean;
	status?: DeliveryOrderStatus;
	courierRoute?: CourierRouteDTO;
	orderItems?: OrderItemReadDTO[];
	statusLog?: OrderStatusLogEntryReadDTO[];
	changes?: OrderChangeDto[];
	itemsTotal?: number;
	receiptsTotal?: number;
	discountSum?: number;
	restaurantId: number;
  portalFee?: number;
}

export interface OrderItemReadDTO extends DTO {
	id: number;
	menuItem: MenuItemMinimalReadDTO;
	quantity: number;
	unitPrice: number;
	discountPercent?: number;
	discountUnitPrice?: number;
	vatPercent?: number;
	note?: string;
	apiName?: string;
	apiNote?: string;
	halfPortion?: boolean;
	sortingNumber?: number;
	toppings?: ToppingOrderItemReadDTO[];
}

export interface ToppingOrderItemReadDTO extends DTO {
	topping: ToppingMinimalReadDTO;
	amount: number;
	unitPrice: number;
	apiName?: string;
	apiNote?: string;
	vatPercent?: number;
	sortingNumber?: number;
	discountUnitPrice?: number;
}

export interface CourierRouteDTO extends DTO {
	id: number;
	courier: UserMinimalReadDTO;
	status: string;
	start?: Date;
	returnTime?: Date;
}

export interface OrderStatusWriteDTO {
	status: string;
	note: string;
}

export interface OrderStatusLogEntryReadDTO extends DTO {
	id: number;
	deliveryOrderId?: number;
	localOrderId?: number;
	status: string;
	note?: string;
	createdAt: Date;
}

export interface LocalOrderMinimalReadDTO extends AuditedDTO {
	id: number;
	createdAt: Date;
	grandTotal?: number;
	serviceFeePercent?: number;
	ntakStatus?: string;
	status?: LocalOrderStatus;
	table?: number;
	type?: DeliveryType;
	removedAt?: Date;
	itemsTotal?: number;
	receiptsTotal?: number;
	discountSum?: number;
}

export interface LocalOrderMinimalReadWithTableDTO extends LocalOrderMinimalReadDTO {
	tableObject?: TableReadDTO;
}

export interface LocalOrderDetailedReadDTO extends LocalOrderMinimalReadDTO {
	paymentTypes?: string[];
	orderItems?: OrderItemReadDTO[];
	receipts?: ReceiptDetailedReadDto[];
	waiter?: UserMinimalReadDTO;
	paid?: boolean;
	dailyOrderNumber?: number;
	completedDate?: Date;
	status?: LocalOrderStatus;
	type?: DeliveryType;
	changeLog?: OrderChangeDto[];

	// private RestaurantMinimalReadDto restaurant;
	// private Integer dailyOrderNumber;
	// private Integer numberOfPersons;
	// private String type;
	// private CustomerMinimalReadDto customer;
}

export interface OrderSearchWrapper {
	courierId?: number;
	restaurantId?: number;
	start?: string;
	end?: string;
}

export interface ReceiptDetailedReadDto {
	id: number;
	billingAddress: AddressDTO;
	discountSum: number;
	grandTotal: number;
	paidAmount: number;
	orderItems: OrderItemReadDTO[];
	packagingFee: number;
	tipSum: number;
	serviceFeeSum: number;
	sortingNumber?: number;
	paid: any;
	restaurant: RestaurantMinimalReadDTO;
	amountsByVat: {
		[key: string | number]: ReceiptDetailsDto;
	};
	payments: any;
}

export interface ReceiptDetailsDto {
	vatAmount: number;
	sum: number;
	serviceFeeNet: number;
	serviceFeeGross: number;
  depositFee: number;
}

export interface OrderInvoiceReadDto {
  id?: number;
  restaurant?: number;
  deliveryOrder?: number;
  localOrder?: number;
  receipt?: number;
  dailyOrderNumber?: number;
  orderType?: DeliveryType;
  status?: 'PENDING' | 'SUCCESSFUL' | 'FAILED';
  invoiceProvider?: 'SZLAHU' | 'BILLINGO';
  invoiceNumber?: string;
  invoiceNote?: string;
  scheduledTime?: string;
}
