import { AddressCountryCode, AddressDTO, WhitelabelUserAddressDto } from 'libs/common/src/models/GeneralModels';
import { DeliveryOrderDetailedReadDTO, DeliveryOrderMinimalReadDTO } from 'libs/common/src/models/order/OrderModels';
import * as yup from 'yup';
import { EMAIL_PATTERN } from './GlobalConstant';
import {
  MarkupTranslationValues,
  MessageKeys,
  NestedKeyOf,
  NestedValueOf,
  RichTranslationValues,
  TranslationValues
} from 'next-intl';

export function getAddressToDeliveryOrder(data?: DeliveryOrderDetailedReadDTO | DeliveryOrderMinimalReadDTO, hideCountry = true, intl?: any) {
  return getAddress(data?.deliveryAddress, hideCountry, intl);
}

export function getAddress(data?: AddressDTO, hideCountry = true, intl?: any) {
	let address: string[] = [];

	if (data) {
		if (data?.zip) {
			address.push(data.zip);
		}

		if (data?.country && !hideCountry) {
      let countryName = data.country;
      if (intl) {
        const restaurantCountryCode = Object.keys(AddressCountryCode).find((code) => code === data?.country);
        countryName = intl.formatMessage({ id: `enums.countryCode.${restaurantCountryCode ?? AddressCountryCode.HU}` });
      }
      address.push(`${countryName},`);
		}

		if (data?.city) {
			address.push(`${data.city},`);
		}

		if (data?.address) {
      address.push((data.address ?? '').replace(/sgt/g, 'sugárút'));
		}

		if (data?.number) {
			address.push(data.number);
		}

		if (data?.building) {
			address.push(data.building);
		}

		if (data?.floor) {
			address.push(data.floor);
		}

		if (data?.apartment) {
			address.push(data.apartment);
		}

		if (data?.intercom) {
			address.push(data.intercom);
		}
	}
	return address.join(' ');
}

export const addressValidationSchema = (t: (param: string) => string, disableZoneCheck?: boolean) => yup.object().shape({
  country: yup.string().required(t('required')),
  state: yup.string().nullable(),
  city: yup.string().required(t('required')),
  zip: yup.string().required(t('required')),
  address: yup.string().required(t('required')),
  number: yup.string().nullable(),
  building: yup.string().nullable(),
  staircase: yup.string().nullable(),
  floor: yup.string().nullable(),
  apartment: yup.string().nullable(),
  intercom: yup.string().nullable(),
  additional: yup.string().nullable(),
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  tag: yup.string().nullable(),
  type: yup.string().oneOf(['DELIVERY', 'BILLING', 'LOCATION']).required(t('required'))
}).test('position-fields', 'Billing fields are required when type is DELIVERY', function(value) {
  const { position, type }: any = value;
  if (disableZoneCheck !== true && type === 'DELIVERY') {
    if (!position?.lat || !position?.lon) {
      return this.createError({
        message: 'Required position fields are missing for delivery type'
      });
    }
  }
  return true;
});

export const billingAddressValidationSchema = (t: (param: string) => string) => yup.object().shape({
  email: yup.string().matches(EMAIL_PATTERN, t('required')).required(t('required')),
  country: yup.string().required(t('required')),
  state: yup.string().required(t('required')),
  city: yup.string().required(t('required')),
  zip: yup.string().required(t('required')),
  address: yup.string().required(t('required')),
  number: yup.string().nullable(),
  building: yup.string().nullable(),
  staircase: yup.string().nullable(),
  floor: yup.string().nullable(),
  apartment: yup.string().nullable(),
  intercom: yup.string().nullable(),
  additional: yup.string().nullable(),
  vatNumber: yup.string().required(t('required')),
  companyName: yup.string().required(t('required')),
  // companyRegistrationNumber: yup.string().required(t('required')),
  type: yup.string().oneOf(['DELIVERY', 'BILLING', 'LOCATION']).required(t('required'))
});

export const getAddressDisplay = (address?: WhitelabelUserAddressDto | AddressDTO) => {
  return `${address?.zip ?? ''} ${address?.city ?? ''}, ${address?.address ?? ''} ${address?.number ?? ''}`;
};
