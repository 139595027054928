import { apiPost, useOrsysContext } from '../../utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query/src/types';

export const useUserRefreshKey = ['user', 'refresh'];

export function useUserRefreshQuery(
  {
    options
  }: {
    options?: UseQueryOptions<any>;
  } = {}
) {
  const queryClient = useQueryClient();
  const { user, load } = useOrsysContext();
  return useQuery({
    ...(options || {}),
    gcTime: 0,
    refetchInterval: 1000 * 60 * 8,
    refetchIntervalInBackground: true,
    queryKey: useUserRefreshKey,
    queryFn: async () => {
      const { data: response } = await apiPost<any>(`/auth/refresh`);
      // queryClient.refetchQueries()
      load();
      return response;
    },
    enabled: user?.id != null
  });
}
