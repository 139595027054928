import 'leaflet/dist/leaflet.css';
import 'react-date-range/dist/styles.css';
// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'libs/common/styles/globals.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import React, { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import OrsysContextProvider from 'libs/common/src/utils/OrsysContext';
import { IntlProvider } from 'react-intl';
import { ToastContainer } from 'react-toastify';
import Alert from '../src/components/widgets/Alert/Alert';
import BrowserHeader from '../src/components/widgets/Header/BrowserHeader';
import { getTranslations } from 'libs/common/src/utils/i18n';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HydrationBoundary, keepPreviousData, QueryClient } from '@tanstack/react-query';
import AcceptTOS from '../src/components/AcceptTOS/AcceptTOS';
import dynamic from 'next/dynamic';
import { Persister, PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { useUserRefreshQuery } from '@orsys/common';

const HotjarComponent = dynamic(() => import('../src/components/HotjarComponent'), { ssr: false });

function MyApp({ Component, pageProps }: AppProps<any>) {
	const [queryClient] = useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						placeholderData: keepPreviousData,
            gcTime: 1000 * 60 // 60 seconds
          }
        }
      })
	);
	const [messages, setMessages] = useState({});
	const [persister, setPersister] = useState<Persister>(
		createSyncStoragePersister({
      storage: undefined
    })
	);

	useEffect(() => {
		setMessages(getTranslations(pageProps.locale));
		setPersister(
			createSyncStoragePersister({
        storage: window.localStorage
      })
		);
	}, [pageProps.locale]);

	return (
		<>
			<BrowserHeader />
			<PersistQueryClientProvider
				client={queryClient}
				persistOptions={{ persister }}>
				<HydrationBoundary state={pageProps.dehydratedState}>
					<IntlProvider
						locale={pageProps.locale}
						messages={messages}>
							<OrsysContextProvider>
                <UserRefresh />
								<ToastContainer
                  position="top-right"
									autoClose={false}
									hideProgressBar={false}
									newestOnTop={false}
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
								/>
                <Component {...pageProps} />
								<Alert />
								<AcceptTOS />
								<HotjarComponent />
							</OrsysContextProvider>
					</IntlProvider>
				</HydrationBoundary>
				<ReactQueryDevtools initialIsOpen={false} />
			</PersistQueryClientProvider>
		</>
	);
}

const UserRefresh = () => {
  const {} = useUserRefreshQuery();

  return <></>;
};

export default MyApp;
