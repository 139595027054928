import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styles from 'libs/common/styles/pages/auth/SignupPage.module.scss';
import componentStyles from './AcceptTOS.module.scss';
import { apiGet, apiPut } from 'libs/common/src/utils/ApiUtils';
import { UserDetailedReadDTO } from 'libs/common/src/models/UserModels';
import { OrsysContext, OrsysContextProviderValues } from 'libs/common/src/utils/OrsysContext';
import { withRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';
import Input from '../widgets/Input/Input';
import Button, { ButtonSize, ButtonVariant } from '../widgets/Button/Button';
import { injectIntl } from 'react-intl';
import { WithIntlComponentProps } from 'libs/common/src/utils/i18n';
import { faClose, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../widgets/Modal/Modal';
import { errorToast } from 'libs/common/src/utils/ToastUtils';
import { TermsOfServicePublicReadDTO } from 'libs/common/src/models/TermOfServiceModels';
import moment from 'moment';
import dynamic from 'next/dynamic';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const CheckBankCard = dynamic(() => import('../CheckBankCard/CheckBankCard'), { ssr: false });

interface AcceptTOSProps extends WithRouterProps, WithIntlComponentProps {}

interface AcceptTOSState {
	requiredToAccept: boolean;
	open: boolean;
}

interface AcceptTOSFormState {
	accepted?: boolean;
}

const AcceptTOS: FC<AcceptTOSProps> = ({ intl }) => {
	const { user, load } = useContext<OrsysContextProviderValues>(OrsysContext);
	const [canAccept, setCanAccept] = useState(false);
	const queryClient = useQueryClient();

	const queryId = ['termsOfService', 'current'];

	const termsOfServiceQuery = useQuery<TermsOfServicePublicReadDTO>({
		queryKey: queryId,
		queryFn: async () => {
			const { data } = await apiGet<TermsOfServicePublicReadDTO>('/tos/current');
			return data;
		},

		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
	});

	const termsOfService = termsOfServiceQuery?.data;

	const { open, requiredToAccept } = useMemo<AcceptTOSState>(() => {
		let requiredToAccept = false;
		let open = false;
		if (
			user &&
			termsOfService &&
			!user?.acceptedList.map((item) => item.termsOfService).includes(termsOfService?.id)
		) {
			let requiredToAccept = moment(termsOfService?.validFrom).isBefore(moment());
			open = true;
			if (!requiredToAccept) {
				if (localStorage.getItem('hideTOS') != null) {
					open = false;
				}
			}
		}
		return {
			requiredToAccept: requiredToAccept,
			open: open,
		};
	}, [termsOfService, user?.acceptedList]);

	const eventListener: EventListenerOrEventListenerObject = (event) => {
		const e = event.target;
		// @ts-ignore
		if (e.clientHeight + e.scrollTop >= e.scrollHeight - 50) {
			setCanAccept(true);
		}
	};

	const setListener = () => {
		try {
			const element = document.querySelector(`.${componentStyles.tosContent}`);
			console.log('element', element);
			if (element) {
				if (element.clientHeight + element.scrollTop === element.scrollHeight) {
					setCanAccept(true);
				}
				element.addEventListener('scroll', eventListener);
			}
		} catch (e) {}
	};

	useEffect(() => {
		setListener();
	}, []);

	useEffect(() => {
		const element = document.querySelector(`.${componentStyles.tosContent}`);
		setListener();
		return () => {
			if (element) {
				element.removeEventListener('scroll', eventListener);
			}
		};
	}, [open]);

	const acceptTOS = async (values: AcceptTOSFormState, { setSubmitting }: FormikHelpers<AcceptTOSFormState>) => {
		try {
			const { data, headers } = await apiPut<UserDetailedReadDTO>(`/tos/${termsOfService?.id}/accept`);
			load();
			queryClient.invalidateQueries({ queryKey: queryId });
			setSubmitting(false);
		} catch (e) {
			errorToast(`${e}`, {
				autoClose: 5000,
			});
			console.error('ERR', e);
		}
	};

	const initLoginState: AcceptTOSFormState = {};
	return (
		<>
			{!open && <CheckBankCard />}
			<Modal
				open={open}
				closeButton={!requiredToAccept}
				onClose={() => {
					if (!requiredToAccept) {
						localStorage.setItem('hideTOS', moment().format());
						queryClient.invalidateQueries({ queryKey: queryId });
					}
				}}>
				<Formik<AcceptTOSFormState>
					initialValues={initLoginState}
					validateOnBlur={true}
					validateOnMount={true}
					validate={(values) => {
						let errors: FormikErrors<AcceptTOSFormState> = {};
						console.log('values.accepted', values.accepted);
						if (!values.accepted) {
							errors.accepted = intl.formatMessage({ id: 'error.required_field' });
						}
						return errors;
					}}
					onSubmit={(values, actions) => acceptTOS(values, actions)}>
					{({ isSubmitting, isValid, errors, touched, values }) => (
						<Form>
							<div className={componentStyles.head}>
								<h2>{intl.formatMessage({ id: 'auth.accept_tos_title' })}</h2>
								<span>{intl.formatMessage({ id: 'auth.accept_tos_description' })}</span>
							</div>
							{termsOfService ? (
								<div
									className={componentStyles.tosContent}
									dangerouslySetInnerHTML={{ __html: termsOfService.content.text }}
								/>
							) : (
								<FontAwesomeIcon
									icon={faSpinner}
									spin
								/>
							)}
							<div className={componentStyles.inputs}>
								<Input
									type='checkbox'
									name='accepted'
									disabled={termsOfService == null || !canAccept}
									className={styles.accepted}
									inputLabel={intl.formatMessage({ id: 'auth.accept_tos' })}
								/>
								<Button
									formType='submit'
									size={ButtonSize.medium}
									variant={ButtonVariant.warning}
									className={`${componentStyles.tosButton}`}
									disabled={isSubmitting || !isValid || termsOfService == null || !canAccept}>
									{intl.formatMessage({ id: 'accept' })}
									{isSubmitting && (
										<FontAwesomeIcon
											icon={faSpinner}
											spin
										/>
									)}
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</Modal>
		</>
	);
};

export default withRouter<AcceptTOSProps>(injectIntl(AcceptTOS));
